import React, {useState, useEffect} from 'react';
import './main.css';
import Logo from './login-header.jpg';
import BackgroundImg from './login-bg.jpg';
import {setDefaultValues} from "../../utils/state";
import Loading from "../../ui/loading";
import {isEmptyStr, isValidEmail} from "../../utils/validation";
import {showNotification} from "../../ui/sweet-alert/notifications";
import firebase from "firebase/app";
import 'firebase/auth';

const LoginForm = ({onLogin, isSubmittingCredentials, isInvalidCredentials}) => {
    const [isLoginActive, setIsLoginActive] = useState(true); //login or reset-password
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [passwordResetError, setPasswordResetError] = useState(null);
    useEffect(() => {
        const _blurElem = ($elem) => {
            if ($elem.val().trim() !== "") {
                $elem.addClass('login-has-val');
            } else {
                $elem.removeClass('login-has-val');
            }
        };
        const $input = window.jQuery('.input100');
        $input.on('blur', function () {
            _blurElem(window.jQuery(this));
        });
        return () => {
            window.jQuery('.input100').off('blur');
        }
    }, [isLoginActive]);
    const [formValues, setFormValues] = useState(setDefaultValues(['email', 'password'], {}, {}));
    /**
     * On field change
     */
    const onValuesChange = ({target}) => {
        setFormValues({...formValues, [target.id]: target.value});
    };
    /**
     * When enter is pressed on input
     */
    const onEnter = (evt) => {
        //on enter
        if (evt.key === 'Enter') {
            evt.preventDefault();
            onLogin(formValues.email, formValues.password);
        }
    };
    return (
        <div className="limiter">
            <div className="container-login100">
                <div className="wrap-login100">
                    <form className="login100-form validate-form">
					<span className="login100-form-title" style={{paddingBottom: '43px'}}>
						<img src={Logo} alt={'Saudi Tracking'}/>
					</span>
                        <div className="wrap-input100">
                            <input className={`input100 login-has-val`} type="text" id="email" onChange={onValuesChange} value={formValues.email} onKeyDown={onEnter}/>
                            <span className="focus-input100"/>
                            <span className="label-input100">{isLoginActive? 'Username or Email': 'Email'}</span>
                        </div>
                        {
                            isLoginActive &&
                            <div className="wrap-input100">
                                <input className={`input100 login-has-val`} type="password" id="password" onChange={onValuesChange} value={formValues.password} onKeyDown={onEnter}/>
                                <span className="focus-input100"/>
                                <span className="label-input100">Password</span>
                            </div>
                        }
                        <div className="login-flex-sb-m"
                             style={{paddingTop: '3px', paddingBottom: '32px', width: '100%'}}>
                            <div>
                                {
                                    isLoginActive &&
                                    <a href="/" className="login-form-link" onClick={(e) => {
                                        e.preventDefault();
                                        setIsLoginActive(false);
                                    }
                                    }>
                                        Forgot Password?
                                    </a>
                                }
                                {
                                    !isLoginActive &&
                                    <a href="/" className="login-form-link" onClick={(e) => {
                                        e.preventDefault();
                                        setIsLoginActive(true);
                                    }
                                    }>
                                        Back to login
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="container-login100-form-btn">
                            {
                                isLoginActive &&
                                <button className="login100-form-btn" style={{fontSize: '18px'}}
                                        disabled={isSubmittingCredentials}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onLogin(formValues.email, formValues.password);
                                        }}>
                                    {isSubmittingCredentials && <Loading/>}
                                    {!isSubmittingCredentials && <span>Login</span>}
                                </button>
                            }
                            {
                                isLoginActive && isInvalidCredentials &&
                                <div className="invalid-feedback" style={{display: 'block'}}>
                                    Invalid username or password!
                                </div>
                            }
                            {
                                !isLoginActive &&
                                <button className="login100-form-btn" style={{fontSize: '18px'}} disabled={isResettingPassword}
                                        onClick={async (e) => {
                                            e.preventDefault();
                                            if(isEmptyStr(formValues.email))
                                            {
                                                return;
                                            }
                                            if(!isValidEmail(formValues.email))
                                            {
                                                setPasswordResetError('Invalid email!');
                                                return;
                                            }
                                            setIsResettingPassword(true);
                                            await firebase.auth().sendPasswordResetEmail(formValues.email).then(async () => {
                                                await showNotification('We sent you a link to reset your password!', {timer: 5000});
                                                setPasswordResetError(null);
                                                setIsLoginActive(true);
                                            }).catch((err) => {
                                                console.error(err);
                                                setPasswordResetError('Invalid account!');
                                            }).finally(() => {
                                                setIsResettingPassword(false);
                                            });
                                        }}>
                                    <span>Reset your password</span>
                                </button>
                            }
                            {
                                !isLoginActive && !!passwordResetError &&
                                <div className="invalid-feedback" style={{display: 'block'}}>
                                    {passwordResetError}
                                </div>
                            }
                        </div>
                    </form>
                    <div className="login100-more" style={{backgroundImage: `url(${BackgroundImg})`}}/>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;