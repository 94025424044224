import React from 'react';
import TopbarOptionsMenu from "../../live-tracking/topbar/options-menu";
import {useApolloClient, useReactiveVar} from "@apollo/client";
import launchModal from "../../common/ui/sweet-alert";
import ImportLandmarksModal from '../import-landmarks';
import ExportToExcelModal from "../../common/ui/export-to-excel";
import EXPORT_TO_EXCEL_GQL from "./export-to-excel.graphql";
import {selectedLandmarks} from "../panel/cache";
import {isUser} from "../../common/auth/guard/check";
import {showConfirmation} from "../../common/ui/sweet-alert/confirmation";
import DELETE_LANDMARK_GQL from './delete-landmarks.graphql';
import {submitMutation} from "../../common/http/mutate";
import {showNotification} from "../../common/ui/sweet-alert/notifications";
import {t} from "../../common/translation";

const LandmarkTopbar = () => {
    const apolloClient = useApolloClient();
    const selectedLandmarksState = useReactiveVar(selectedLandmarks);

    return (
        <div className="top-menu ms-auto">
            <ul className="navbar-nav align-items-center">
                <TopbarOptionsMenu/>
                {/* import */}
                {
                    (isUser(['create-landmarks'])) &&
                    <div className="btn-group" role="group" aria-label="Import landmarks">
                    <button type="button" className="btn btn-outline-dark" title={t('Import landmarks')} onClick={() => {
                    launchModal(ImportLandmarksModal, {apolloClient});
                }}>
                    <i className="bx bx-cloud-upload"/>
                    </button>
                    </div>
                }
                {/* export */}
                <div className="btn-group" role="group" aria-label="Export landmarks">
                    <button type="button" className="btn btn-outline-dark" title={t('Export landmarks')} onClick={() => {
                        launchModal(ExportToExcelModal, {apolloClient, GQL_PATH: EXPORT_TO_EXCEL_GQL, mutationVariables: {filter: {}}});
                    }}>
                        <i className="bx bx-download"/>
                    </button>
                </div>
                {/* delete */}
                {
                    (isUser(['delete-landmarks'])) &&
                    <div className="btn-group" role="group" aria-label="Export landmarks">
                        <button type="button" className="btn btn-outline-dark" disabled={selectedLandmarksState.length === 0} title={t('Delete selected landmarks')} onClick={async () => {
                            for(const record of selectedLandmarksState)
                            {
                                if(!record.isLandmarkOwner)
                                {
                                    let error = 'This landmark was';
                                    if(selectedLandmarksState.length > 1)
                                    {
                                        error = 'One or more landmarks were';
                                    }
                                    error = t(`${error} created by another account and cannot be deleted!`);
                                    await showNotification(error, {icon: 'error'});
                                    return;
                                }
                            }
                            showConfirmation(
                                t(`This will delete {COUNT} selected landmark${selectedLandmarksState.length > 1? 's': ''}`).replace('{COUNT}', selectedLandmarksState.length),
                                async (RAlert) => {
                                    const params = [
                                        apolloClient,
                                        DELETE_LANDMARK_GQL,
                                        {landmarkIDs: selectedLandmarksState.map(r => r.id)},
                                        ()=>{},
                                        ()=>{},
                                    ];
                                    const {error} = await submitMutation(...params);
                                    if(error)
                                    {
                                        await showNotification(error, {icon: 'error'});
                                    }
                                    else {
                                        RAlert.close();
                                        window.location.reload();
                                    }
                                },
                            );
                        }}>
                            <i className="bx bx-trash"/>
                        </button>
                    </div>
                }
            </ul>
        </div>
    );
};

export default LandmarkTopbar;