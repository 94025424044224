/**
 Controls rendering of components based on account types
*/

import {accountVar} from '../cache';

export const isMaster = () => {
    return accountVar()?.isMaster;
};

export const isDistributor = () => {
    return accountVar()?.isDistributor;
};

/**
 * @param<Array> permissions
 * @param<String> profile
 * @return {boolean}
 */
export const isUser = (permissions=null, profile=null) => {
    if(!accountVar()?.isUser)
    {
        return false;
    }
    if(permissions !== null)
    {
        const userPermissions = accountVar().permissions;
        for(let entry of permissions)
        {
            if(!userPermissions.includes(entry))
            {
                return false;
            }
        }
    }
    if(profile !== null)
    {
        return accountVar().profiles.includes(profile);
    }
    return true;
};