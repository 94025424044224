import React from 'react';
import {isRtl} from "../../translation";

const Footer = () => {
    return (
        <footer className={`page-footer app-direction-${isRtl()? 'rtl': 'ltr'}`}>
            <p className="mb-0">
                © {new Date().getFullYear()} Saudi Tracking Solutions
            </p>
        </footer>
    );
};

export default Footer;