import moment from 'moment';
import {accountVar} from "../auth/cache";
import 'moment/locale/ar';
import {isRtl} from "../translation";

export const DB_DATE_FORMAT = 'YYYY-MM-DD';
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const CLIENT_DATE_FORMAT = 'MMMM Do YYYY';
export const CLIENT_DATETIME_FORMAT = 'MMMM Do YYYY, h:mm a';
export const TIME_FORMAT_24H = 'HH:mm';
export const TIME_FORMAT_12H = 'hh:mm A';

//the master doesn't define preferences, cluster's time is in UTC, default to UAE
const DEFAULT_OFFSET_SECS = 14400;

//default locale to English, Arabic is used selectively
moment.locale('en');

/**
 * Returns localized datetime
 */
export const getUserDateTime = (dateStr, format=CLIENT_DATETIME_FORMAT) => {
    if(!dateStr) {
        return null;
    }
    return moment(dateStr, format).add(accountVar().timezoneOffsetSecs ?? DEFAULT_OFFSET_SECS, 'second').format(format);
};

/**
 * Returns date object from a string of the format YYYY-MM-DD
 */
export const getLocalDbDate = (dateStr, format=DB_DATE_FORMAT) => {
    return moment(dateStr, format).toDate();
};

/**
 * Formats `jsDate`
 */
export const formatLocalDate = (jsDate, format=DB_DATE_FORMAT) => {
    return moment(jsDate).format(format);
};

/**
 * Returns duration of seconds formatted as 00:00:00
 */
export const formatDigitalClock = (seconds) => {
    return moment.utc(moment.duration(seconds, 'second').asMilliseconds()).format("HH:mm:ss");
};

/**
 * Extracts time from date, ex: 21:30
 */
export const timeFromDate = (jsDate) => {
    return moment(jsDate).format(TIME_FORMAT_24H);
};

/**
 * Converts a 24h-string to UTC equivalent
 */
export const localTimeSegmentToUTC = (h24) => {
    return moment(h24, TIME_FORMAT_24H).subtract(accountVar().timezoneOffsetSecs ?? DEFAULT_OFFSET_SECS, 'second').format(TIME_FORMAT_24H);
};

/**
 * 1 -> 1st
 */
export const getMonthDaySuffix = (day) => {
    if([1, 21, 31].includes(day))
    {
        return 'st';
    }
    if([2, 22].includes(day))
    {
        return 'nd';
    }
    if([3, 23].includes(day))
    {
        return 'rd';
    }
    return 'th';
};

/**
 * Time from now/ago, ex: 2 hours ago
 */
export const fromNow = (dateStr, format=DB_DATE_TIME_FORMAT) => {
    return moment(dateStr, format, isRtl()? 'ar': 'en').add(accountVar().timezoneOffsetSecs ?? DEFAULT_OFFSET_SECS, 'second').fromNow();
};