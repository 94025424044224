import React from 'react';
import {userPreferencesVar} from "../../common/user/settings/cache";
import {useApolloClient, useReactiveVar} from "@apollo/client";
import {setUserPreference} from "../../common/user/settings/set-preference";
import {useLocation} from "react-router-dom";
import launchModal from "../../common/ui/sweet-alert";
import LiveTrackingListView from '../list-view';
import {FULLSCREEN_MODAL_OPTIONS} from "../../common/ui/sweet-alert/fullscreen";
import PlaybackModal from "../../playback/main/modal";
import {t} from "../../common/translation";

const TopbarOptionsMenu = () => {
    const apolloClient = useApolloClient();
    const userPreferencesState = useReactiveVar(userPreferencesVar);
    const location = useLocation();

    return (
        <li className="nav-item dropdown dropdown-large">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="" role="button"
               data-bs-toggle="dropdown" aria-expanded="false">
                <i className="bx bx-category"/>
            </a>
            <div className="dropdown-menu dropdown-menu-end">
                <div className="row row-cols-3 g-3 p-3">
                    {/* map type */}
                    <div className="col text-center" onClick={async () => {
                        const settingKey = 'defaultMapDisplay';
                        const newValue = userPreferencesState?.[settingKey] === 'map'? 'earth': 'map';
                        await setUserPreference(apolloClient, settingKey, newValue);
                        userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                    }
                    }>
                        <div className="app-box mx-auto bg-gradient-burning text-white">
                            <i className={`bx ${userPreferencesState?.defaultMapDisplay === 'map'? 'bx-map-alt': 'bx-map'}`}/>
                        </div>
                        <div className="app-title">{
                            t(userPreferencesState?.defaultMapDisplay === 'map'? 'Satellite': 'Map')
                        }</div>
                    </div>
                    {/* traffic */}
                    <div className="col text-center" onClick={async () => {
                        const settingKey = 'showLiveTraffic';
                        const newValue = !userPreferencesState?.[settingKey];
                        await setUserPreference(apolloClient, settingKey, newValue);
                        userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                    }
                    }>
                        <div className={`app-box mx-auto ${!!userPreferencesState?.showLiveTraffic? 'bg-gradient-lush text-white': ''}`}>
                            <i className={`bx bxs-traffic`}/>
                        </div>
                        <div className="app-title">{t('Toggle Traffic')}</div>
                    </div>
                    {/* showMapPointsOfInterest */}
                    <div className="col text-center" onClick={async () => {
                        const settingKey = 'showMapPointsOfInterest';
                        const newValue = !userPreferencesState?.[settingKey];
                        await setUserPreference(apolloClient, settingKey, newValue);
                        userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                    }
                    }>
                        <div className={`app-box mx-auto ${!!userPreferencesState?.showMapPointsOfInterest? 'bg-gradient-lush text-white': ''}`}>
                            <i className={`bx bxs-landmark`}/>
                        </div>
                        <div className="app-title">{t('Toggle POIs')}</div>
                    </div>
                    {/* followTracker */}
                    {
                        (location.pathname === '/live/track' || location.pathname === '/playback') &&
                        <div className="col text-center" onClick={async () => {
                            const settingKey = 'followTracker';
                            const newValue = !userPreferencesState?.[settingKey];
                            await setUserPreference(apolloClient, settingKey, newValue);
                            userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                        }
                        }>
                            <div className="app-box mx-auto bg-gradient-kyoto text-white">
                                <i className={`bx ${!!userPreferencesState?.followTracker? 'bx-strikethrough': 'bx-trip'}`}/>
                            </div>
                            <div className="app-title">{
                                t(!!userPreferencesState?.followTracker? 'Unfollow Tracker': 'Follow Tracker')
                            }</div>
                        </div>
                    }
                    {/* displayLandmarksInLiveView */}
                    {
                        location.pathname !== '/landmarks' &&
                        <div className="col text-center" onClick={async () => {
                            const settingKey = 'displayLandmarksInLiveView';
                            const newValue = !userPreferencesState?.[settingKey];
                            await setUserPreference(apolloClient, settingKey, newValue);
                            userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                        }
                        }>
                            <div className={`app-box mx-auto ${!!userPreferencesState?.displayLandmarksInLiveView? 'bg-gradient-lush text-white': ''}`}>
                                <i className={`bx bxs-landmark`}/>
                            </div>
                            <div className="app-title">{t('Toggle Landmarks')}</div>
                        </div>
                    }
                    {/* displayGeofencesInLiveView */}
                    {
                        location.pathname !== '/geofences/geometry' &&
                        <div className="col text-center" onClick={async () => {
                            const settingKey = 'displayGeofencesInLiveView';
                            const newValue = !userPreferencesState?.[settingKey];
                            await setUserPreference(apolloClient, settingKey, newValue);
                            userPreferencesVar({...userPreferencesState, [settingKey]: newValue});
                        }
                        }>
                            <div className={`app-box mx-auto ${!!userPreferencesState?.displayGeofencesInLiveView? 'bg-gradient-lush text-white': ''}`}>
                                <i className={`bx bx-shape-polygon`}/>
                            </div>
                            <div className="app-title">{t('Toggle Geofences')}</div>
                        </div>
                    }
                    {/* list view */}
                    <div className="col text-center" onClick={async () => {
                        launchModal(LiveTrackingListView, {apolloClient}, FULLSCREEN_MODAL_OPTIONS);
                    }}>
                        <div className="app-box mx-auto bg-gradient-blues text-white">
                            <i className={`bx bx-list-ol`}/>
                        </div>
                        <div className="app-title">{t('List View')}</div>
                    </div>
                    {/* playback */}
                    <div className="col text-center" onClick={async () => {
                        launchModal(PlaybackModal, {apolloClient}, FULLSCREEN_MODAL_OPTIONS);
                    }}>
                        <div className="app-box mx-auto bg-gradient-cosmic text-white">
                            <i className={`bx bx-history`}/>
                        </div>
                        <div className="app-title">{t('Playback')}</div>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default TopbarOptionsMenu;