import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloClient, ApolloProvider, ApolloLink} from '@apollo/client';
import {cache} from './cache';
import AuthController from "./common/auth/controller";
import {createUploadLink} from 'apollo-upload-client';
import * as serviceWorker from './serviceWorker';
import App from "./app";
import UserPreferencesAndTranslation from "./common/user/settings/loader";

/**
 * Initialize Apollo client
 */
const linkOptions = {
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URI.replace('%host', window.location.hostname),
    fetch: (uri, options) => {
        options.headers['x-token'] = sessionStorage.getItem('impersonationToken') ?? localStorage.getItem('token');
        return fetch(uri, options);
    }
};
/**
 * Locally injected __typename by InMemoryCache causes backend-exceptions when mutations are submitted.
 * This middleware intercepts all requests (Query & Mutations) & strips out __typename.
 * solution thanks to https://librenepal.com/article/remove-typenames-in-apollo-graphql/
 * more here: https://www.apollographql.com/docs/link/links/http/
 * @type {ApolloLink}
 */
const apolloMiddleware = new ApolloLink((operation, forward)=>{
    return forward(operation);
});
const client = new ApolloClient({
    cache,
    link: apolloMiddleware.concat(createUploadLink(linkOptions))
});


ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthController>
            <UserPreferencesAndTranslation>
                <App/>
            </UserPreferencesAndTranslation>
        </AuthController>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
