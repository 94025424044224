/**
 Search filter
 */

import React, {useState, useEffect} from 'react';
import {t} from "../../translation";

let searchFilterTimeout;
const SearchFilter = ({notifyOnChange, value, placeholder='Search', autoFocus=true}) => {
    const [searchQuery, setSearchQuery] = useState(value);
    /**
     * Clears search on escape
     */
    useEffect(() => {
        const inputID = '#search-filter';
        window.jQuery(inputID).on('keydown.search', (e) => {
            if(e.which === 27) //Escape
            {
                if(searchQuery === '')
                {
                    return;
                }
                setSearchQuery('');
                notifyOnChange('');
            }
        });
        return () => {
            window.jQuery(inputID).off('keydown.search');
        };
    }, [notifyOnChange, searchQuery]);

    /**
     * On search change
     */
    const onSearchInputChange = ({target}) => {
        setSearchQuery(target.value);
        if(searchFilterTimeout)
        {
            clearTimeout(searchFilterTimeout);
            searchFilterTimeout = null;
        }
        searchFilterTimeout = setTimeout(() => {
            notifyOnChange(target.value);
        }, 1600);
    };
    return (
        <div className="position-relative search-bar-box ms-2" style={{maxWidth: '300px'}}>
            <input type="text" id="search-filter" className="form-control search-control" placeholder={t(placeholder)} autoFocus={autoFocus}
                   onChange={onSearchInputChange} value={searchQuery} style={{maxWidth: '380px', paddingRight: '30px'}}/>
            <span className="position-absolute top-50 search-show translate-middle-y">
                <i className="bx bx-search"/>
            </span>
            <span className="position-absolute top-50 search-close translate-middle-y">
                <i className="bx bx-x"/>
            </span>
        </div>
    );
};

export default React.memo(SearchFilter);