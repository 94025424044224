import React, {useEffect} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {isDistributor, isMaster, isUser} from '../../auth/guard/check';
import Logo from './logo.png';
import FavIcon from './favicon.png';
import {FLEET_PROFILE, WAREHOUSE_PROFILE} from "../../auth/profiles";
import {isRtl, langDirStyle, t} from "../../translation";

const Menu = ({location}) => {
    useEffect(() => {
        //setup menu
        window.jQuery("#menu").metisMenu();
        //menu toggle
        const _onHover = () => {
            $sidebarWrapper.hover(function() {
                $wrapper.addClass("sidebar-hovered");
            }, function() {
                $wrapper.removeClass("sidebar-hovered");
            });
        };
        let $wrapper = window.jQuery(".wrapper");
        let $sidebarWrapper = window.jQuery(".sidebar-wrapper");
        window.jQuery('#menu-toggle').click(() => {
            let $wrapper = window.jQuery(".wrapper");
            let $sidebarWrapper = window.jQuery(".sidebar-wrapper");
            if($wrapper.hasClass("toggled"))
            {
                $wrapper.removeClass("toggled");
                $sidebarWrapper.unbind("hover");
            }
            else {
                $wrapper.addClass("toggled");
                _onHover();
            }
        });
        _onHover();
        return () => {
            $sidebarWrapper.unbind("hover");
        };
    }, []);
    const path = location.pathname;
    const isHomeSectionActive = path === '/';
    const isVehicleTypesSectionActive = path === '/vehicle-types';
    const isReportSetupSectionActive = path === '/reports-setup';
    const isLanguageSetupSectionActive = path === '/language-setup';
    const isCompaniesSectionActive = path === '/companies';
    const isCompanyLicensesSectionActive = path === '/company-licenses';
    const isUsersSectionActive = path === '/users';
    const isReportAssignmentSectionActive = path === '/reports-assignment';
    const isEventsDefaultRegistrySectionActive = path === '/events-default-registry';
    const isEventsUserRegistrySectionActive = path === '/events-user-registry';
    const isEventsIODisplaySectionActive = path === '/events-io-display';
    const isTrackersSectionActive = path === '/trackers';
    const isDriversSectionActive = path === '/drivers';
    const isWarehousesSectionActive = /^\/warehouses\//.test(path);
    const isSensorAlertsSectionActive = path === '/sensor-alerts';
    const isLiveTrackingSectionActive = path === '/live';
    const isGeofencesSectionActive = path === '/geofences';
    const isLandmarksSectionActive = path === '/landmarks';
    const isReportsSectionActive = path === '/reports';
    const isRFIDTagsSectionActive = path === '/rfid-tags';
    const isPacketsRadarSectionActive = path === '/packets-radar';
    return (
        <div className={`sidebar-wrapper app-direction-${isRtl()? 'rtl': 'ltr'}`} data-simplebar="true" style={{...langDirStyle()}}>
            <div className="sidebar-header">
                <div>
                    <img src={FavIcon} className="logo-icon" alt="logo icon"/>
                </div>
                <div>
                    <img src={Logo} alt={'Saudi Tracking'} style={{width: '90%'}}/>
                </div>
                <div id="menu-toggle" className="toggle-icon ms-auto">
                    <i className='bx bx-arrow-to-left'/>
                </div>
            </div>
            {/* navigation */}
            <ul className="metismenu" id="menu">
                {
                    isMaster() &&
                    <li className={`${isHomeSectionActive? 'mm-active': ''}`}>
                        <Link to={'/'}>
                            <div className="parent-icon">
                                <i className='bx bx-category-alt'/>
                            </div>
                            <div className="menu-title">{t('Device Models')}</div>
                        </Link>
                    </li>
                }
                {
                    isMaster() &&
                    <li className={`${isVehicleTypesSectionActive? 'mm-active': ''}`}>
                        <Link to={'/vehicle-types'}>
                            <div className="parent-icon">
                                <i className='bx bx-car'/>
                            </div>
                            <div className="menu-title">{t('Vehicle Types')}</div>
                        </Link>
                    </li>
                }
                {
                    isMaster() &&
                    <li className={`${isReportSetupSectionActive? 'mm-active': ''}`}>
                        <Link to={'/reports-setup'}>
                            <div className="parent-icon">
                                <i className='bx bx-file'/>
                            </div>
                            <div className="menu-title">{t('Reports')}</div>
                        </Link>
                    </li>
                }
                {
                    isMaster() &&
                    <li className={`${isLanguageSetupSectionActive? 'mm-active': ''}`}>
                        <Link to={'/language-setup'}>
                            <div className="parent-icon">
                                <i className='bx bx-text'/>
                            </div>
                            <div className="menu-title">{t('Translation')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isCompaniesSectionActive? 'mm-active': ''}`}>
                        <Link to={'/companies'}>
                            <div className="parent-icon">
                                <i className="bx bxs-business"/>
                            </div>
                            <div className="menu-title">{t('Companies')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser() &&
                    <li className={`${isHomeSectionActive? 'mm-active': ''}`}>
                        <Link to={'/'}>
                            <div className="parent-icon">
                                <i className='bx bxs-dashboard'/>
                            </div>
                            <div className="menu-title">{t('Home')}</div>
                        </Link>
                    </li>
                }
                {
                    (isDistributor() || isUser(['view-tracker-licenses'], FLEET_PROFILE)) &&
                    <li className={`${isCompanyLicensesSectionActive? 'mm-active': ''}`}>
                        <Link to={'/company-licenses'}>
                            <div className="parent-icon">
                                <i className="bx bxs-dollar-circle"/>
                            </div>
                            <div className="menu-title">{t('Licenses')}</div>
                        </Link>
                    </li>
                }
                {
                    (isDistributor() || isUser(['view-users'])) &&
                    <li className={`${isUsersSectionActive? 'mm-active': ''}`}>
                        <Link to={'/users'}>
                            <div className="parent-icon">
                                <i className="bx bxs-group"/>
                            </div>
                            <div className="menu-title">{t('Users')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isReportAssignmentSectionActive? 'mm-active': ''}`}>
                        <Link to={'/reports-assignment'}>
                            <div className="parent-icon">
                                <i className="bx bxs-report"/>
                            </div>
                            <div className="menu-title">{t('Reports')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isEventsDefaultRegistrySectionActive? 'mm-active': ''}`}>
                        <Link to={'/events-default-registry'}>
                            <div className="parent-icon">
                                <i className="bx bxs-notification"/>
                            </div>
                            <div className="menu-title">{t('Event Registry')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser(['view-user-events-registry'], FLEET_PROFILE) &&
                    <li className={`${isEventsUserRegistrySectionActive? 'mm-active': ''}`}>
                        <Link to={'/events-user-registry'}>
                            <div className="parent-icon">
                                <i className="bx bxs-notification"/>
                            </div>
                            <div className="menu-title">{t('Event Registry')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isEventsIODisplaySectionActive? 'mm-active': ''}`}>
                        <Link to={'/events-io-display'}>
                            <div className="parent-icon">
                                <i className="bx bxs-layer-plus"/>
                            </div>
                            <div className="menu-title">{t('Event Display')}</div>
                        </Link>
                    </li>
                }
                {
                    (isDistributor() || isUser(['view-trackers'], FLEET_PROFILE)) &&
                    <li className={`${isTrackersSectionActive? 'mm-active': ''}`}>
                        <Link to={'/trackers'}>
                            <div className="parent-icon">
                                <i className="bx bxs-truck"/>
                            </div>
                            <div className="menu-title">{t('Trackers')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isRFIDTagsSectionActive? 'mm-active': ''}`}>
                        <Link to={'/rfid-tags'}>
                            <div className="parent-icon">
                                <i className="bx bx-station"/>
                            </div>
                            <div className="menu-title">{t('RFID Tags')}</div>
                        </Link>
                    </li>
                }
                {
                    (isDistributor() || isUser(['view-drivers'], FLEET_PROFILE)) &&
                    <li className={`${isDriversSectionActive? 'mm-active': ''}`}>
                        <Link to={'/drivers'}>
                            <div className="parent-icon">
                                <i className="bx bxs-user-circle"/>
                            </div>
                            <div className="menu-title">{t('Drivers')}</div>
                        </Link>
                    </li>
                }
                {
                    (isDistributor() || isUser(['view-warehouses'], WAREHOUSE_PROFILE)) &&
                    <li className={`${isWarehousesSectionActive? 'mm-active': ''}`}>
                        <Link to={'/warehouses'}>
                            <div className="parent-icon">
                                <i className="bx bxs-cube"/>
                            </div>
                            <div className="menu-title">{t('Warehouses')}</div>
                        </Link>
                    </li>
                }
                {
                    isDistributor() &&
                    <li className={`${isPacketsRadarSectionActive? 'mm-active': ''}`}>
                        <Link to={'/packets-radar'}>
                            <div className="parent-icon">
                                <i className="bx bx-radar"/>
                            </div>
                            <div className="menu-title">{t('Packets Radar')}</div>
                        </Link>
                    </li>
                }
                {
                    (isUser(['manage-sensor-alerts'])) &&
                    <li className={`${isSensorAlertsSectionActive? 'mm-active': ''}`}>
                        <Link to={'/sensor-alerts'}>
                            <div className="parent-icon">
                                <i className="bx bxs-bell-ring"/>
                            </div>
                            <div className="menu-title">{t('Sensor Alerts')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser(['view-trackers'], FLEET_PROFILE) &&
                    <li className={`${isLiveTrackingSectionActive? 'mm-active': ''}`}>
                        <Link to={'/live'}>
                            <div className="parent-icon">
                                <i className="bx bxs-map"/>
                            </div>
                            <div className="menu-title">{t('Live Tracking')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser(['view-geofences'], FLEET_PROFILE) &&
                    <li className={`${isGeofencesSectionActive? 'mm-active': ''}`}>
                        <Link to={'/geofences'}>
                            <div className="parent-icon">
                                <i className="bx bx-shape-polygon"/>
                            </div>
                            <div className="menu-title">{t('Geofences')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser(['view-landmarks'], FLEET_PROFILE) &&
                    <li className={`${isLandmarksSectionActive? 'mm-active': ''}`}>
                        <Link to={'/landmarks'}>
                            <div className="parent-icon">
                                <i className="bx bxs-landmark"/>
                            </div>
                            <div className="menu-title">{t('Landmarks')}</div>
                        </Link>
                    </li>
                }
                {
                    isUser(['generate-user-reports']) &&
                    <li className={`${isReportsSectionActive? 'mm-active': ''}`}>
                        <Link to={'/reports'}>
                            <div className="parent-icon">
                                <i className="bx bxs-report"/>
                            </div>
                            <div className="menu-title">{t('Reports')}</div>
                        </Link>
                    </li>
                }
            </ul>
        </div>
    );
};

export default withRouter(Menu);